@import "~cakemail-ui-components-v2/dist/assets/scss/fonts.scss";

.loadingContainer-component-v2 {
    .circulatProgress-component-v2 {
        margin-top: 4rem;
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
}

.dialog-component-v2 {
    .MuiDialog-container {
        align-items: flex-start;
    }
}